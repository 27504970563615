import { useEffect, useState } from "react";
import { getProjectInfo } from "../gitlabApi/getProjectInfo";
import { useAppSelector } from "../redux/store";
import { getGroupProjects } from "../gitlabApi/getGroupProjects";

export interface Project {
  name: string;
  webUrl: string;
  id: number;
}

export interface Product {
  name: string;
  projects: Project[];
}

interface ProductRaw {
  name: string;
  projects: number[];
  groupIds: number[];
}

export const productAll = "Search All";

const multilabProductsRaw: ProductRaw[] = [
  {
    name: "Mappa",
    projects: [],
    groupIds: [80],
  },
  {
    name: "BPS",
    projects: [],
    groupIds: [60],
  },
  {
    name: "MassWallet",
    projects: [],
    groupIds: [243],
  },
  {
    name: "Хаб",
    projects: [],
    groupIds: [81],
  },
  {
    name: "ZeroConfirmation",
    projects: [],
    groupIds: [62],
  },
  {
    name: "ACS Licensing, clientix",
    projects: [],
    groupIds: [130, 129],
  },
  {
    name: "Clicker",
    projects: [165],
    groupIds: [],
  },
  {
    name: "Lottery",
    projects: [],
    groupIds: [273],
  },
  {
    name: "Builder",
    projects: [],
    groupIds: [271],
  },
  {
    name: "Cendterra",
    projects: [176],
    groupIds: [],
  },
  {
    name: "General",
    projects: [9],
    groupIds: [],
  },
  {
    name: productAll,
    projects: [],
    groupIds: [],
  },
];

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

let multilabProducts: Product[] = [];

let raceCondition = false;
export const initProducts = async () => {
  try {
    while (raceCondition) await sleep(500);
    raceCondition = true;
    if (multilabProducts.length > 0) {
      return { products: multilabProducts, err: undefined };
    }
    multilabProducts = await Promise.all(
      multilabProductsRaw.map<Promise<Product>>(async (p) => {
        const groupProjectsPromises = p.groupIds.map((gId) =>
          getGroupProjects(gId)
        );
        const groupProjects = (await Promise.all(groupProjectsPromises)).flat();
        const projectsAll = await Promise.all(
          [...p.projects, ...groupProjects].map((id) => getProjectInfo(id))
        );
        const projects = projectsAll.filter((pj) => !!pj) as Project[];
        return { name: p.name, projects };
      })
    );
    return { products: multilabProducts, err: undefined };
  } catch (err: any) {
    return { products: [], err: err.message as string };
  } finally {
    raceCondition = false;
  }
};

export const useProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((s) => s.user.user);

  useEffect(() => {
    const act = async () => {
      if (!user?.username) return;
      setLoading(true);
      const { products, err } = await initProducts();
      setProducts([...products]);
      setError(err);
      setLoading(false);
    };
    act();
  }, [user?.username]);

  return { products, error, loading };
};
